import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back-button',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
})
export class BackButtonComponent {
  @Output() emitBackClick: EventEmitter<void> = new EventEmitter<void>();

  @Input() loading: boolean;

  @Input() emitEvent: boolean;

  constructor(private location: Location) {
    this.loading = false;
  }

  onClick() {
    if (this.emitEvent) this.emitBackClick.emit();
    else if (this.hasHistory) {
      this.location.back();
    }
  }

  get hasHistory() {
    return window.history.length > 1;
  }

  get disabled() {
    return this.loading || (!this.emitEvent && !this.hasHistory);
  }
}
