<div class="back-button">
  <p-button
    [text]="true"
    size="small"
    icon="/assets/login/microsoft-logo.svg"
    [disabled]="disabled"
    (click)="onClick()"
    (keyup.enter)="onClick()"
    tabindex="0"
  >
    <div class="d-flex align-items-center justify-content-start">
      <img src="/assets/icons/left-angle.svg" alt="back" />
      <p>Back</p>
    </div>
  </p-button>
</div>
